import * as React from 'react';
import styled from 'styled-components';
// import {useStaticQuery, graphql} from 'gatsby';
// import {GatsbyImage, getImage} from 'gatsby-plugin-image';
// import Gallery from '@browniebroke/gatsby-image-gallery';

import '@fontsource/shadows-into-light';
import '@fontsource/sacramento';
import 'fontsource-open-sans/300.css';
import 'normalize.css';
import Page from '../components/Page';
import Box from '../components/Box';
import Title from '../components/Title';
import ContentWrapper from '../components/ContentWrapper';

// styles
const TitleWrapper = styled(Box).attrs({
  mt: '35px',
})``;

const SubTitle = styled(Box).attrs({
  mt: '40px',
  mb: '25px',
  fontSize: ['20px', null, null, '22px'],
})`
  font-family: 'Open Sans';
  font-weight: bold;
`;
const Content = styled(Box).attrs({
  mb: '30px',
  fontSize: ['20px', null, null, '22px'],
})`
  font-family: 'Open Sans';
`;

const Anchor = styled.a`
  color: #6d7770;
  margin-top: 5px;
  word-wrap: break-word;
  :hover {
    color: black;
  }
`;

const List = styled.ul`
  text-align: left;
`;

const SkiingPage = () => {
  return (
    <Page>
      <ContentWrapper>
        <TitleWrapper>
          <Title text={'Skiing'} />
        </TitleWrapper>
        <Box m={'0 auto'} maxWidth={'1000px'}>
          <Content>
            The Ritz has its own Chairlift right outside on the hotel and it leads to the entire mountain (but some of
            our favorite skiing is right there at the Ritz also!) The skiing is world class and you can bring your own
            or rent gear right in Beaver Creek or at the hotel.
          </Content>
          <SubTitle>Lift Tickets</SubTitle>
          <Content>
            Lift tickets are available through BeaverCreek Resort:{' '}
            <Anchor href={'https://www.beavercreek.com/plan-your-trip/lift-access/tickets.aspx'}>
              Beaver Creek Lift Tickets
            </Anchor>
            . They may be purchased for any number of days and certain passes give you the option of skipping days if
            you want to take a break. The best prices are found when purchasing in advance. If you plan on skiing more
            than 3 days we recommend looking into an{' '}
            <Anchor href={'https://www.beavercreek.com/plan-your-trip/lift-access/passes.aspx'}>Epic Local Pass</Anchor>
            . This is a season pass that allows up to 10 days on Vail, Beaver Creek, and Whistler mountains and
            unlimited on many other resorts across the country.
          </Content>
          <SubTitle>Rentals</SubTitle>
          <Content>
            **Best deals when booked in advance of arrival***
            <br />
            Locations in AVON are typically less expensive as they are not directly on the mountain. You can pick your
            skis up the day before your first day of skiing, at no additional charge, so you can get on the mountain
            right away the next day. Many locations also deliver for an additional fee.
          </Content>
          <Content>
            <i>Information about ski options</i>
            <br />
            Demo level- Advanced level skiing ability
            <br />
            Performance- Intermediate level skiing ability
            <br />
            Sport level- Beginner level skiing ability
          </Content>
          <SubTitle>Recommended Shops</SubTitle>
          <Content>
            AVON
            <br />
            <Anchor href={'https://avonventuresports.com/our-locations/avon-ski-shop'}>Venture Sports</Anchor>
            <br />
            <Anchor href={'https://www.christysports.com/store-locations/avon.html'}>Christy Sports</Anchor>
            <br />
            <Anchor href={'https://www.chartersports.com'}>Charter Sports</Anchor>
            <br />
            <Anchor href={'https://www.basemountainsports.com/equipment-rates/4/avon-wyndham/'}>
              Base Mountain Sports
            </Anchor>
          </Content>
          <Content>
            BACHELOR GULCH/RITZ
            <br />
            <Anchor href={'https://avonventuresports.com/our-locations/bachelor-gulch-ski-shop'}>Venture Sports</Anchor>
          </Content>
          <Content>
            BEAVER CREEK VILLAGE
            <br />
            <Anchor href={'https://www.christysports.com/store-locations/beaver-creek.html'}>Christy Sports</Anchor>
            <br />
            <Anchor href={'https://www.chartersports.com'}>Charter Sports</Anchor>
            <br />
            <Anchor href={'https://www.basemountainsports.com/equipment-rates/3/beaver-creek'}>
              Base Mountain Sports
            </Anchor>
          </Content>
        </Box>
      </ContentWrapper>
    </Page>
  );
};

export default SkiingPage;
